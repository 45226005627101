.nav-bar {
  position: absolute;
  background-color: white;
  z-index: 100000;
  border: 1px solid black;
  padding: 5px;
  margin: 5px;
  margin-left: 50%;
  width: 120px;
  left: -60px;
}
.nav-bar .country-code {
  position: absolute;
  z-index: 100001;
  top: 20px;
  background-color: white;
  left: 47px;
  padding-right: 3px;
  padding-left: 3px;
}
.nav-bar .site-intro {
  position: absolute;
  z-index: 100001;
  top: -10px;
  background-color: white;
  left: 31px;
  /* padding-right: 3px;
  padding-left: 3px; */
}
